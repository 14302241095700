import { useMsal } from "@azure/msal-react"
import { Login } from "@doktor-se/bones-ui/dist/web-shared/components"
import { loginRequest } from "@doktor-se/bones-ui/dist/web-shared/config"
import { authorizeWithToken, authorize, itsmeContinue, itsmeStart } from "api/auth"
import { featureFlags, itsmeEnabled, project } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { useState, useEffect, useMemo } from "react"
import { useIntl } from "react-intl"
import { useLocation } from "react-router-dom"

interface LoginProps {
  redirectedFrom?: "itsme"
}

const LogIn = ({ redirectedFrom }: LoginProps) => {
  const dispatch = useAppDispatch()
  const loginError = useAppSelector(state => state.error.loginError)
  const passwordLoginError = useAppSelector(state => state.error.passwordLoginError)
  const location = useLocation()
  const intl = useIntl()
  const { instance } = useMsal()

  const [logo, setLogo] = useState("")
  const [phone, setPhone] = useState("")
  const [loadingAfterRedirect, setLoadingAfterRedirect] = useState(false)

  useEffect(() => {
    let ignore = false

    const getImage = async (image: string) => {
      try {
        return await import(`../../img/${project}/${image}.png`)
      } catch {
        return undefined
      }
    }

    const fetchImages = async () => {
      const logo = await getImage("logo")
      const phone = await getImage("login_phone")

      if (!ignore) {
        setLogo(logo && logo.default)
        setPhone(phone && phone.default)
      }
    }

    fetchImages()
    return () => {
      ignore = true
    }
  }, [])

  useEffect(() => {
    const token = location.hash.substring(1)
    if (token) dispatch(authorizeWithToken(token))
  }, [location.hash, dispatch])

  const login = (data: { username: string; password: string; roles?: string[] }) => {
    data.roles = ["dashboard", "admin", "staff"]
    dispatch(authorize(data))
  }

  useEffect(() => {
    if (redirectedFrom === "itsme") {
      const params = new URLSearchParams(location.search)
      const code = params.get("code")
      const state = params.get("state")
      if (code && state) {
        setLoadingAfterRedirect(true)
        dispatch(itsmeContinue(code, state, intl))
      }
    }
  }, [dispatch, redirectedFrom, location.search, intl])

  useEffect(() => {
    if (loginError) {
      setLoadingAfterRedirect(false)
    }
  }, [loginError])

  const loginVariant = useMemo(() => {
    return featureFlags.has("sso") ? () => instance.loginRedirect(loginRequest) : undefined
  }, [instance])

  return (
    <Login
      logo={logo}
      phone={phone}
      externalProviderLoginError={loginError}
      passwordLoginError={passwordLoginError}
      loginSso={loginVariant}
      loginItsme={itsmeEnabled ? () => dispatch(itsmeStart()) : undefined}
      loginPassword={!featureFlags.has("disable_email_password_login") ? login : undefined}
      loading={loadingAfterRedirect}
      texts={{
        title: intl.formatMessage({ id: "login.title" }),
        loginSso: intl.formatMessage({ id: "login.sso" }),
        loginItsme: intl.formatMessage({ id: "login.itsme" }),
        loginAlternative: intl.formatMessage({ id: "login.internal" }),
        errorRequired: intl.formatMessage({ id: "error.required" }),
        placeholderUsername: intl.formatMessage({ id: "login.username" }),
        placeholderPassword: intl.formatMessage({ id: "login.password" }),
        loginButton: intl.formatMessage({ id: "login" }),
        loading: intl.formatMessage({ id: "login.loading" })
      }}
    />
  )
}

export default LogIn
