import { datadogRum } from "@datadog/browser-rum"
import { logoutMSAppOnly } from "@doktor-se/bones-ui/dist/web-shared/config"
import { featureFlags } from "config"
import apiFetch from "lib/apiFetch"
import { ReduxDispatch, Thunk } from "lib/hooks"
import { IntlShape } from "react-intl"
import { authorized, unauthorized } from "reducers/auth"
import { loginError, passwordLoginError } from "reducers/error"

export interface AuthTokens {
  token?: string
  accessToken?: string
  refreshToken?: string
}

const isObject = (test: unknown): test is { [key: string]: unknown } => {
  return typeof test === "object" && test !== null && typeof test !== "function"
}

export const getAccessToken = (tokens: AuthTokens) => {
  return {
    accessToken: tokens.accessToken || "",
    refreshToken: tokens.refreshToken || ""
  }
}

const handleFailedLogin = (dispatch: ReduxDispatch, error: unknown, password = false) => {
  let errorMessage = "Unknown error"
  if (typeof error === "string") {
    errorMessage = error
  } else if (isObject(error)) {
    if (isObject(error.details) && typeof error.details.message === "string") {
      errorMessage = error.details.message
    } else if (typeof error.message === "string") {
      errorMessage = error.message
    } else if (typeof error.status === "number") {
      errorMessage = error.status.toString()
    }
  }

  dispatch(password ? passwordLoginError(errorMessage) : loginError(errorMessage))
}

interface LoginResponse {
  token?: string
  refreshToken?: string
  accessToken?: string
}

export const authorize =
  (data: { username: string; password: string; roles?: string[] }): Thunk =>
  dispatch =>
    dispatch(
      apiFetch<LoginResponse>("/v1/auth/password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
    )
      .then(response =>
        dispatch(
          authorized(
            getAccessToken({
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
              token: response.token
            })
          )
        )
      )
      .catch(error => handleFailedLogin(dispatch, error, true))

export const authorizeWithToken =
  (token: string): Thunk =>
  dispatch =>
    dispatch(
      apiFetch(
        "/dashboard",
        {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          }
        },
        "",
        // In case of the raspberry pi we have hardcoded token which will be used
        // If there is a hardcoded token then the fetch api will bypass the accessToken from the store
        token
      )
    )
      .then(() => {
        dispatch(authorized({ accessToken: token }))
      })
      .catch(() => {})

export const authorizeWithMS =
  (token: string, appId: string): Thunk =>
  dispatch => {
    dispatch(
      apiFetch<LoginResponse>("/v1/auth/azure_ad", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ azure_id_token: token, app_id: appId })
      })
    )
      .then(response => {
        const authTokens = getAccessToken({
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          token: response.token
        })
        dispatch(
          authorized({
            accessToken: authTokens.accessToken,
            refreshToken: authTokens.refreshToken,
            token: authTokens.accessToken,
            AD: true
          })
        )
      })
      .catch(error => handleFailedLogin(dispatch, error))
  }

interface ItsmeStartResponse {
  type: "continue_redirect"
  redirectUri: string
  state: string
}

const ItsmeStateStorageKey = "itsmeState"

export const itsmeStart = (): Thunk => async dispatch => {
  dispatch(
    apiFetch<ItsmeStartResponse>("/login/itsme/start", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ use_test_itsme: featureFlags.has("itsme_test") })
    })
  )
    .then(response => {
      localStorage.setItem(ItsmeStateStorageKey, response.state)
      window.location.assign(response.redirectUri)
    })
    .catch(error => handleFailedLogin(dispatch, error))
}

type ItsmeContinueResponse =
  | {
      type: "success"
      token: string
      roles: string[]
      state: string
    }
  | {
      type: "must_register"
      state: string
    }

export const itsmeContinue =
  (code: string, state: string, intl: IntlShape): Thunk =>
  async dispatch => {
    dispatch(
      apiFetch<ItsmeContinueResponse>("/login/itsme/continue", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          use_test_itsme: featureFlags.has("itsme_test"),
          state: localStorage.getItem(ItsmeStateStorageKey),
          query: { code, state }
        })
      })
    )
      .then(response => {
        switch (response.type) {
          case "success":
            dispatch(authorized(getAccessToken(response)))
            break
          case "must_register":
            throw new Error(intl.formatMessage({ id: "login.error.nomatch" }))
          default:
            // @ts-ignore: Expected type error
            datadogRum.addError(new Error(`Unknown response type ${response.type} for itsme login`))
            throw new Error("Something went wrong")
        }
      })
      .catch(error => handleFailedLogin(dispatch, error))
  }

export const logout = (): Thunk => (dispatch, getState) => {
  const { AD } = getState().auth
  if (AD) {
    logoutMSAppOnly()
  }
  dispatch(unauthorized())
  datadogRum.removeUser()
}
