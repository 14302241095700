import { calculateDashboard } from "./calculate"
import { ApiDashboard } from "api/dashboard"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type Weekday = "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday"

export interface DashboardState {
  categories: string[]
  queues?: {
    [queue: string]: {
      count: number
      longestQueueTime: number | string
    }
  }
  ratings?: string
  conversations?: {
    today: number
    lastWeek: number
    lastWeekTot: number
    percentage: number | null
  }
  users?: {
    usersToday: number
    usersThisWeek: number
  }
  online?: {
    staff: number
    patient: number
  }
  weekday: Weekday
}

const initialState: DashboardState = {
  categories: [],
  weekday: "monday" as const
}

const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    loadDashboard(state, action: PayloadAction<ApiDashboard>) {
      const calculatedState = calculateDashboard(state, action.payload)
      state.categories = calculatedState.categories
      state.queues = calculatedState.queues
      state.ratings = calculatedState.ratings
      state.conversations = calculatedState.conversations
      state.users = calculatedState.users
      state.online = calculatedState.online
      state.weekday = calculatedState.weekday
    }
  }
})

export const { loadDashboard } = dashboard.actions
export type DashboardSliceAction = ObjectFunctionReturnTypes<typeof dashboard.actions>

export default dashboard.reducer
