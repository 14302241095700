import { DashboardState, Weekday } from "."
import { ApiDashboard } from "api/dashboard"
import { queueWithSound } from "config"

const weekDays = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday"
}

const setCategories = (categories: { [category: string]: number }) => {
  const categoryKeys = Object.keys(categories)
  if (categoryKeys.length) {
    const length = categoryKeys.length > 3 ? 3 : categoryKeys.length
    const totalNumberOfConversations = categoryKeys.reduce((acc, key) => acc + categories[key], 0)
    return categoryKeys
      .map(key => [key, categories[key]])
      .sort((a, b) => (b as [string, number])[1] - (a as [string, number])[1])
      .slice(0, length)
      .map(category => `${category[0]} (${Math.round(((category[1] as number) / totalNumberOfConversations) * 100)}%)`)
  }
  return []
}

const setRatings = (ratings: { percentage: number; count: number }) => {
  if (ratings.percentage) return `${Math.round(ratings.percentage * 100)}%`
  return
}

const formatLongestQueueTime = (queues: {
  [queue: string]: {
    count: number
    longestQueueTime: string | number
  }
}) => {
  Object.keys(queues).forEach(queue => {
    queues[queue].longestQueueTime = Math.round(
      (new Date().getTime() - new Date(queues[queue].longestQueueTime).getTime()) / 60000
    )
  })

  if (!queues[queueWithSound]) {
    queues[queueWithSound] = { count: 0, longestQueueTime: 0 }
  }

  return queues
}

const setConversations = (conversations: {
  createdToday: number
  createdLastWeek: number
  createdLastWeekTotal: number
}) => {
  const today = conversations.createdToday || 0
  const lastWeek = conversations.createdLastWeek || 0
  const lastWeekTot = conversations.createdLastWeekTotal || 0
  let percentage: number | null = null

  if (lastWeek !== 0 && today > lastWeek) {
    percentage = Math.round(((today - lastWeek) / lastWeek) * 100)
  } else if (lastWeek === 0) {
    percentage = today * 100
  }

  return {
    today,
    lastWeek,
    lastWeekTot,
    percentage
  }
}

export const calculateDashboard = (state: DashboardState, data: ApiDashboard): DashboardState => ({
  ...state,
  categories: setCategories(data.categories),
  queues: formatLongestQueueTime(data.queues || {}),
  ratings: setRatings(data.ratings),
  conversations: setConversations(data.conversations),
  users: data.users,
  online: data.online,
  weekday: (weekDays as { [key: number]: Weekday })[new Date().getDay()]
})

export default calculateDashboard
