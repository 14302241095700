import apiFetch from "lib/apiFetch"
import { Thunk } from "lib/hooks"
import { unauthorized } from "reducers/auth"
import { loadDashboard } from "reducers/dashboard"
import { logError } from "reducers/error"

export interface ApiDashboard {
  queues?: {
    [queue: string]: {
      count: number
      longestQueueTime: string
    }
  }
  conversations: { createdToday: number; createdLastWeek: number; createdLastWeekTotal: number }
  ratings: { percentage: number; count: number }
  categories: {
    [category: string]: number
  }
  users: { usersToday: number; usersThisWeek: number }
  online: { staff: number; patient: number }
}

export const getMonitorData = (): Thunk => (dispatch, getState) => {
  dispatch(
    apiFetch<ApiDashboard>("/dashboard", {
      method: "GET",
      headers: {
        Authorization: getState().auth.accessToken || "",
        "Content-Type": "application/json"
      }
    })
  )
    .then(response => dispatch(loadDashboard(response)))
    .catch(error => {
      switch (error.status) {
        case 401:
        case 403:
          dispatch(unauthorized())
          break
        default:
          dispatch(logError())
          break
      }
    })
}

export default getMonitorData
