import styles from "./Dashboard.module.scss"
import { StaffBodyTextM } from "@doktor-se/bones-ui"
import { logoutMSAppOnly } from "@doktor-se/bones-ui/dist/web-shared/config"
import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { getMonitorData } from "api/dashboard"
import audioFile from "audio/monitor-queue.mp3"
import Controls from "components/Controls/Controls"
import Anicura from "components/layout/Anicura"
import Doktor from "components/layout/Doktor"
import Proximus from "components/layout/Proximus"
import StandardDoctorsAndNurses from "components/layout/StandardDoctorsAndNurses"
import { queueWithSound, project } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { useEffect, useState, useRef } from "react"
import { toggleSound } from "reducers/app"
import { unauthorized } from "reducers/auth"

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const dashboard = useAppSelector(state => state.dashboard)
  const error = useAppSelector(state => state.error.error)
  const sound = useAppSelector(state => state.app.sound)
  const AD = useAppSelector(state => state.auth.AD)

  const audio = useRef<HTMLAudioElement>(null)
  const prevDefaultQueue = usePrevious(dashboard.queues && dashboard.queues[queueWithSound]) as unknown as {
    count: number
    longestQueueTime: string
  }
  const [monitorInterval, setMonitorInterval] = useState<ReturnType<typeof setInterval>>()

  useEffect(() => {
    dispatch(getMonitorData())
    setMonitorInterval(
      setInterval(() => {
        dispatch(getMonitorData())
      }, 5000)
    )
  }, [dispatch])

  useEffect(
    () => () => {
      if (monitorInterval) clearInterval(monitorInterval)
    },
    [monitorInterval]
  )

  useEffect(() => {
    if (!!audio.current) audio.current.volume = 0.15
  }, [])

  useEffect(() => {
    const currentDefaultQueue = dashboard.queues && dashboard.queues[queueWithSound]
    if (sound && prevDefaultQueue && currentDefaultQueue && prevDefaultQueue.count < currentDefaultQueue.count) {
      audio.current?.play()
    }
  }, [dashboard.queues, prevDefaultQueue, sound])

  const logout = () => {
    if (AD) {
      logoutMSAppOnly()
    }
    dispatch(unauthorized())
  }
  return (
    <div className={styles.container}>
      {error.show && (
        <div className={styles.error}>
          <StaffBodyTextM>{error.message}</StaffBodyTextM>
        </div>
      )}

      <audio ref={audio}>
        <source src={audioFile} type="audio/mp3" />
        <track kind="captions" />
      </audio>

      <Controls sound={sound} toggleSound={() => dispatch(toggleSound())} logout={() => logout()} />

      {project === "doktor" && <Doktor dashboard={dashboard} />}
      {project === "anicura" && <Anicura dashboard={dashboard} />}
      {project === "proximus" && <Proximus dashboard={dashboard} />}
      {project === "doktorde" && <StandardDoctorsAndNurses dashboard={dashboard} />}
    </div>
  )
}

export default Dashboard
