import classNames from "classnames"
import React from "react"
import { FormattedMessage } from "react-intl"

import styles from "./Percentage.module.scss"

interface PercentageProps {
  data: number
  weekday: JSX.Element
  stretch?: boolean
}

const Percentage: React.FC<PercentageProps> = ({ data, weekday, stretch }) => (
  <div className={classNames(styles.container, { [styles.stretch]: stretch })}>
    <div className={styles.percentage}>
      <div>{`${data}%`}</div>
    </div>
    <div className={styles.description}>
      <FormattedMessage id="percentage.description" values={{ weekday }} />
    </div>
  </div>
)

export default Percentage
