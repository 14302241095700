import { FetchRequestHeader } from "@doktor-se/bones-ui/dist/web-shared/types"
import { logout } from "api/auth"
import { appApiUrl } from "config"
import { refreshTokenDefaultValidity } from "config"
import { Thunk } from "lib/hooks"
import { RootState } from "reducers"
import { AppAction } from "reducers"
import { setAuthTokens } from "reducers/auth"
import { ThunkDispatch } from "redux-thunk"
import { apiFetchWrapper } from "@doktor-se/bones-ui/dist/web-shared/libs/fetch/fetchLib"

const getRefreshAuthTokenUrl = () => `${appApiUrl}/v1/auth/refresh`

const getRequestHeaderCallback =
  (getState: () => RootState, hardcodedToken?: string) =>
  (propertyName: FetchRequestHeader): string => {
    switch (propertyName) {
      case "accessToken":
        return hardcodedToken || (getState().auth.accessToken as string)
      case "refreshToken":
        return getState().auth.refreshToken as string
      case "language":
        return getState().app.language as string
      default:
        return ""
    }
  }

const authorizeCallback =
  (dispatch: ThunkDispatch<RootState, any, AppAction>) =>
  async (refreshTokenResponse: any): Promise<Response> => {
    const responseJson = await refreshTokenResponse.json()
    const formattedResponse = {
      accessToken: responseJson.access_token,
      refreshToken: responseJson.refresh_token
    }

    await dispatch(setAuthTokens(formattedResponse))

    return refreshTokenResponse
  }

export const apiFetch =
  <Result = any>(
    url: string,
    incomingOptions?: { [key: string]: any },
    context?: string,
    hardcodedToken?: string
  ): Thunk<Promise<Result>> =>
  async (dispatch, getState) => {
    const res = (await apiFetchWrapper({
      url: `${appApiUrl}${url}`,
      refreshAuthTokenUrl: getRefreshAuthTokenUrl(),
      incomingOptions,
      refreshTokenDefaultValidity,
      context,
      // In case of the raspberry pi we have hardcoded token which will be used
      // If there is a hardcoded token then the fetch api will bypass the accessToken from the store
      getRequestHeader: getRequestHeaderCallback(getState, hardcodedToken),
      authorize: authorizeCallback(dispatch),
      logout: () => dispatch(logout())
    })) as unknown

    return res as Result
  }

export default apiFetch
