import classNames from "classnames"
import React from "react"

import styles from "./Box.module.scss"

interface BoxProps {
  children?: JSX.Element | null
  data?: number | string | string[]
  description: JSX.Element | string
  color: string
  className?: string
  stretch?: boolean
  categories?: boolean
}

const setSize = (content: number | string | string[]) => {
  let check: string | string[]
  if (typeof content === "number") {
    check = content.toString()
  } else {
    check = content
  }

  if (check) {
    if (check.length > 9) return "size10"
    if (check.length > 6) return "size7"
    if (check.length > 3) return "size4"
    if (check.length > 2) return "size3"
  }

  return "size2"
}

const Box: React.FC<BoxProps> = ({ children, data = 0, description, color, className, stretch, categories }) => (
  <div className={classNames(styles.box, styles[color], className, { [styles.stretch]: stretch })}>
    <div className={classNames(styles.value, styles[setSize(data)], { [styles.categories]: categories })}>
      {Array.isArray(data) ? data.map(d => <div key={d}>{d}</div>) : data}
    </div>
    {children}
    <div className={styles.description}>{description}</div>
  </div>
)

export default Box
