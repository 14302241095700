import React from "react"
import { FormattedMessage, useIntl } from "react-intl"

import Box from "components/Box/Box"
import Percentage from "components/Percentage/Percentage"
import { DashboardState } from "reducers/dashboard"
import Grid from "components/Grid/Grid"
import styles from "./Proximus.module.scss"

interface DashboardProps {
  dashboard: DashboardState
}

const Proximus: React.FC<DashboardProps> = ({ dashboard }) => {
  const intl = useIntl()

  return (
    <Grid>
      <Box
        data={dashboard.ratings || intl.formatMessage({ id: "rating.empty" })}
        description={<FormattedMessage id="positive.ratings" />}
        color="color1"
        className={styles.box1}
        stretch
      />

      <Box
        data={dashboard.queues?.doctor?.count ?? 0}
        description={<FormattedMessage id="queue.doctor" />}
        color="color2"
        className={styles.box2}
      />

      <Box
        data={dashboard.queues?.coord?.count ?? 0}
        description={<FormattedMessage id="queue.coord" />}
        color="color2"
        className={styles.box3}
      />

      <Box
        data={dashboard.queues?.psych?.count ?? 0}
        description={<FormattedMessage id="queue.psych" />}
        color="color2"
        className={styles.box4}
      />

      <Box
        data={dashboard.queues?.rtwcoach?.count ?? 0}
        description={<FormattedMessage id="queue.rtwcoach" />}
        color="color2"
        className={styles.box5}
      />

      <Box
        data={dashboard.conversations?.today}
        description={<FormattedMessage id="conversations.today" />}
        color="color3"
        className={styles.box6}
        stretch>
        {dashboard.conversations?.percentage && dashboard.conversations.percentage > 0 ? (
          <Percentage
            data={dashboard.conversations.percentage}
            weekday={<FormattedMessage id={`weekday.${dashboard.weekday}`} />}
            stretch
          />
        ) : null}
      </Box>

      <Box
        data={intl.formatMessage({ id: "time.minutes" }, { time: dashboard.queues?.doctor?.longestQueueTime ?? "0" })}
        description={<FormattedMessage id="queue.time.doctor" />}
        color="color4"
        className={styles.box7}
      />

      <Box
        data={intl.formatMessage({ id: "time.minutes" }, { time: dashboard.queues?.coord?.longestQueueTime ?? "0" })}
        description={<FormattedMessage id="queue.time.coord" />}
        color="color4"
        className={styles.box8}
      />

      <Box
        data={dashboard.conversations?.lastWeek}
        description={
          intl.formatMessage(
            { id: "conversations.weekday.time" },
            { weekday: <FormattedMessage id={`weekday.${dashboard.weekday}`} /> }
          ) as string
        }
        color="color3"
        className={styles.box9}
      />

      <Box
        data={dashboard.conversations?.lastWeekTot}
        description={
          intl.formatMessage(
            { id: "conversations.weekday" },
            { weekday: <FormattedMessage id={`weekday.${dashboard.weekday}`} /> }
          ) as string
        }
        color="color3"
        className={styles.box10}
      />

      <Box
        data={dashboard.categories.length ? dashboard.categories : intl.formatMessage({ id: "symptoms.empty" })}
        description={<FormattedMessage id="common.symptoms" />}
        color="color7"
        className={styles.box11}
        categories
        stretch
      />

      <Box
        data={dashboard.users?.usersToday}
        description={<FormattedMessage id="patients.today" />}
        color="color6"
        className={styles.box12}
      />

      <Box
        data={dashboard.users?.usersThisWeek}
        description={<FormattedMessage id="patients.week" />}
        color="color6"
        className={styles.box13}
      />

      <Box
        data={dashboard.online?.patient}
        description={<FormattedMessage id="online.patients" />}
        color="color5"
        className={styles.box14}
      />

      <Box
        data={dashboard.online?.staff}
        description={<FormattedMessage id="online.staff" />}
        color="color5"
        className={styles.box15}
      />
    </Grid>
  )
}

export default Proximus
