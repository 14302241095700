import { datadogRum } from "@datadog/browser-rum"
// Polyfills to fix the paspberry pi dashboards at the doktor.se office
import "@formatjs/intl-getcanonicallocales/polyfill"
import "@formatjs/intl-locale/polyfill"
import "@formatjs/intl-pluralrules/locale-data/sv"
import "@formatjs/intl-pluralrules/polyfill"
import { appLanguages, project } from "config"
import { useAppSelector } from "lib/hooks"
import React, { useState, useEffect } from "react"
import { IntlProvider } from "react-intl"

type Translations = { [key: string]: string }

const fetchLanguage = async (lang: string) => {
  return import(`../../localise/${project}/${lang}.json`)
}

const Localization = ({ children }: { children: React.ReactNode }) => {
  const [translations, setTranslations] = useState<Translations | "not-loaded">("not-loaded")

  const currentLanguage = useAppSelector(state => state.app.language)
  const supportedLanguage =
    appLanguages.find(lang => currentLanguage === lang.code)?.code || appLanguages[0]?.code || "en"
  const locale = appLanguages.find(lang => currentLanguage === lang.code)?.locale || appLanguages[0]?.locale || "en-GB"

  useEffect(() => {
    fetchLanguage(supportedLanguage)
      .then(importedTranslations => setTranslations(importedTranslations.default))
      .catch(error => {
        setTranslations({})
        datadogRum.addError(error)
      })
  }, [supportedLanguage])

  return (
    <IntlProvider locale={locale} messages={translations === "not-loaded" ? {} : translations}>
      {translations === "not-loaded" ? null : children}
    </IntlProvider>
  )
}

export default Localization
