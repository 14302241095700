import styles from "./Controls.module.scss"
import classNames from "classnames"
import LogoutIcon from "components/Icons/LogoutIcon"
import SoundIcon from "components/Icons/SoundIcon"

interface ControlsProps {
  sound: boolean
  toggleSound: () => void
  logout: () => void
}

const Controls = ({ sound, toggleSound, logout }: ControlsProps) => (
  <div className={styles.controls}>
    <SoundIcon className={classNames(styles.soundIcon, { [styles.sound]: sound })} onClick={toggleSound} />
    <LogoutIcon className={styles.logoutIcon} onClick={logout} />
  </div>
)

export default Controls
