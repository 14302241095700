/* Migrations for store state
Introducing a new reducer does not require a migration.
The migration that is run has to match the version number in persistConfig.
*/

const migrations = {
  0: (state: any) => ({
    ...state,
    auth: {
      ...state.auth,
      AD: false
    }
  }),
  1: (state: any) => ({
    ...state,
    auth: {
      ...state.auth,
      AD: false,
      token: "",
      accessToken: "",
      refreshToken: undefined
    }
  })
}

export default migrations
