import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface AuthState {
  authorized: boolean
  token?: string
  accessToken?: string
  refreshToken?: string
  AD: boolean
  fetchingRefreshToken: boolean
}

const initialState: AuthState = {
  authorized: false,
  AD: false,
  fetchingRefreshToken: false
}

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authorized(
      state,
      action: PayloadAction<{
        token?: string
        refreshToken?: string
        accessToken?: string
        AD?: boolean
      }>
    ) {
      state.authorized = true
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
      state.accessToken = action.payload.accessToken
      if (action.payload.AD) {
        state.AD = action.payload.AD
      }
    },
    unauthorized(state) {
      state.authorized = false
      state.token = undefined
      state.accessToken = undefined
      state.refreshToken = undefined
      state.AD = false
    },
    setAuthTokens(state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
    fetchingRefreshToken(state, action: PayloadAction<boolean>) {
      state.fetchingRefreshToken = action.payload
    }
  }
})

export const { authorized, unauthorized, setAuthTokens, fetchingRefreshToken } = auth.actions

export type AuthSliceAction = ObjectFunctionReturnTypes<typeof auth.actions>

export default auth.reducer
