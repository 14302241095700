import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"

import Login from "components/Login/Login"
import Dashboard from "components/Dashboard/Dashboard"
import AuthorizedRoute from "./AuthorizedRoute/AuthorizedRoute"

const App: React.FC = () => (
  <Routes>
    <Route
      path="/login/itsme"
      element={
        <AuthorizedRoute>
          <Login redirectedFrom="itsme" />
        </AuthorizedRoute>
      }
    />
    <Route
      path="/login"
      element={
        <AuthorizedRoute>
          <Login />
        </AuthorizedRoute>
      }
    />
    <Route
      path="/dashboard"
      element={
        <AuthorizedRoute>
          <Dashboard />
        </AuthorizedRoute>
      }
    />
    <Route path="*" element={<Navigate to="/dashboard" replace />} />
  </Routes>
)

export default App
