import { ThunkDispatch, ThunkAction } from "redux-thunk"
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux"

import { RootState } from "index"
import { AppAction } from "reducers"

export type ReduxDispatch = ThunkDispatch<RootState, any, AppAction>

export const useAppDispatch = (): ReduxDispatch => {
  return useDispatch<ReduxDispatch>()
}

export type Thunk<Result = void> = ThunkAction<Result, RootState, any, AppAction>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
