import * as React from "react"

const SoundIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props} viewBox="0 0 46 47">
    <g fill="none" fillRule="evenodd" transform="translate(.081 .505)">
      <circle cx={23.14} cy={23.202} r={22.753} fill="currentColor" opacity={0.4} />
      <g stroke="#FFF" strokeWidth={1.247} transform="translate(13.953 15.503)">
        <rect width={3.368} height={6.817} x={0.662} y={5.21} rx={0.802} />
        <path d="M3.968 4.998a.178.178 0 0 0-.077.147v6.885c0 .06.03.115.08.148l6.212 4.135a.178.178 0 0 0 .278-.149V.884a.178.178 0 0 0-.28-.147l-6.213 4.26z" />
        <path
          strokeLinecap="round"
          d="M13.27 11.816a3.374 3.374 0 0 0 .005-6.253m1.048 8.482a5.888 5.888 0 0 0 .008-10.909"
        />
      </g>
    </g>
  </svg>
)

export default SoundIcon
