import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { authorized, unauthorized } from "reducers/auth"
import { loadDashboard } from "reducers/dashboard"

interface ErrorState {
  loginError?: string
  passwordLoginError?: string
  error: {
    show: boolean
    message: string
  }
}

const initialState: ErrorState = {
  error: {
    show: false,
    message: "Something is wrong the data is currently not updating."
  }
}

const error = createSlice({
  name: "error",
  initialState,
  reducers: {
    logError(state) {
      state.error.show = true
    },
    loginError(state, action: PayloadAction<string | undefined>) {
      state.loginError = action.payload
      state.passwordLoginError = undefined
    },
    passwordLoginError(state, action: PayloadAction<string | undefined>) {
      state.loginError = undefined
      state.passwordLoginError = action.payload
    }
  },
  extraReducers: builder =>
    builder
      .addCase(authorized, state => {
        state.loginError = undefined
        state.passwordLoginError = undefined
      })
      .addCase(unauthorized, state => {
        state.loginError = undefined
        state.passwordLoginError = undefined
      })
      .addCase(loadDashboard, state => {
        state.error.show = false
      })
})

export const { logError, loginError, passwordLoginError } = error.actions
export type ErrorSliceAction = ObjectFunctionReturnTypes<typeof error.actions>

export default error.reducer
