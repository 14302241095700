import { combineReducers } from "redux"

import auth, { AuthSliceAction } from "./auth"
import app, { AppSliceAction } from "./app"
import error, { ErrorSliceAction } from "./error"
import dashboard, { DashboardSliceAction } from "./dashboard"

const reducer = combineReducers({
  auth,
  app,
  error,
  dashboard
})

export type RootState = ReturnType<typeof reducer>
export type AppAction = AppSliceAction | AuthSliceAction | ErrorSliceAction | DashboardSliceAction

export default reducer
