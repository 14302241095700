import { LanguageCode } from "@doktor-se/bones-ui/dist/web-shared/types"
import { createSlice } from "@reduxjs/toolkit"
import { appLanguages } from "config"

interface AppState {
  sound: boolean
  language: LanguageCode
}

const initialState: AppState = {
  sound: false,
  language: appLanguages[0]?.code as LanguageCode
}

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleSound(state) {
      state.sound = !state.sound
    }
  }
})

export const { toggleSound } = app.actions
export type AppSliceAction = ObjectFunctionReturnTypes<typeof app.actions>

export default app.reducer
