import * as React from "react"

const LogoutIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={22} {...props} viewBox="0 0 26 22">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#FFF"
        strokeWidth={1.868}
        d="M6.845 4.65v12.037c0 1.052 1.083 1.972 2.488 1.972h11c1.405 0 2.488-.92 2.488-1.972V4.65c0-1.052-1.083-1.971-2.488-1.971h-11c-1.405 0-2.488.92-2.488 1.971z"
      />
      <path
        fill="#FFF"
        d="M11.236 9.619V6.54l5.214 4.073-5.214 4.184v-3.04H3.443c-.682 0-1.22-.563-1.22-1.088 0-.526.444-1.05 1.22-1.05h7.793z"
      />
    </g>
  </svg>
)

export default LogoutIcon
