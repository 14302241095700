import { ThemeName } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Platform } from "@doktor-se/bones-ui/dist/web-shared/types"

const useAlternativeZone = new URLSearchParams(window.location.search).get("alt_zone")
const zone =
  (useAlternativeZone && import.meta.env.VITE_APP_SERVICE_ZONE_ALT) || import.meta.env.VITE_APP_SERVICE_ZONE || ""
export const appApiUrl: string = (import.meta.env.VITE_APP_API || "") + zone

const isProject = (project?: string): project is Platform => {
  const knownPlatforms: Platform[] = ["doktor", "anicura", "proximus", "doktorde"]
  return !!project && knownPlatforms.includes(project as Platform)
}
const projectEnv = import.meta.env.VITE_APP_PROJECT
if (!isProject(projectEnv)) {
  throw new Error(`Unknown project config ${projectEnv}`)
}
export const project: Platform = projectEnv

interface AppLanguage {
  name: string
  code: string
  locale: string
}

const allAppLanguages: { [key in Platform]: AppLanguage[] } = {
  doktor: [
    { name: "Swedish", code: "sv", locale: "sv-SE" },
    { name: "English", code: "en", locale: "en-GB" }
  ],
  anicura: [
    { name: "Swedish", code: "sv", locale: "sv-SE" },
    { name: "English", code: "en", locale: "en-GB" },
    { name: "Danish", code: "da", locale: "da-DK" },
    { name: "Norwegian", code: "nb", locale: "nb-NO" },
    { name: "German", code: "de", locale: "de-DE" }
  ],
  proximus: [{ name: "English", code: "en", locale: "en-GB" }],
  doktorde: [
    { name: "German", code: "de", locale: "de-DE" },
    { name: "English", code: "en", locale: "en-GB" }
  ]
}

export const appLanguages = allAppLanguages[project]
export const refreshTokenDefaultValidity = Number(import.meta.env.REACT_REFRESH_TOKEN_VALIDITY) || 86400

// Please update this notion page if the feature list changes https://www.notion.so/doktorse/Platform-customization-guide-42e2d1a8fde34f44947491d4fb0611e2
type FeatureFlags =
  | "disable_email_password_login" // Removes alternative (email & password) login for security reasons. We need to have sso sign-in turned on in that case!
  | "itsme" // Enable the itsme login method
  | "itsme_test" // Enable the itsme login method for itsme test accounts
  | "sso" // Single sign on login enabled

const featureFlagsEnv = import.meta.env.VITE_APP_FEATURE_FLAGS
export const featureFlags = new Set((featureFlagsEnv?.split(",").map((f: any) => f.trim()) || []) as FeatureFlags[])

// This queue will make a sound when conversations increase and sound is on
export const queueWithSound = import.meta.env.VITE_APP_QUEUE_WITH_SOUND || "nurse"
export const datadogConfig = {
  applicationId: import.meta.env.VITE_APP_DATADOG_APP_ID!,
  clientToken: import.meta.env.VITE_APP_DATADOG_TOKEN!,
  environment: import.meta.env.VITE_APP_ENVIRONMENT!,
  project: project,
  version: import.meta.env.VITE_APP_VERSION!,
  service: "dashboard-ui"
}

export const themeName: ThemeName = (import.meta.env.VITE_APP_THEME as ThemeName) || "default"

export const itsmeEnabled = featureFlags.has("itsme") || featureFlags.has("itsme_test")
