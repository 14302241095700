import { Theme } from "@doktor-se/bones-ui/dist/web-shared/components"
import { MsalEntryProvider } from "@doktor-se/bones-ui/dist/web-shared/config"
import { datadogInit, ErrorBoundary } from "@doktor-se/more-rum-please"
import App from "components/App"
import Localization from "components/localization/Localize"
import { themeName, datadogConfig } from "config"
import migrations from "migrations"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import reducer from "reducers"
import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction"
import { persistStore, persistReducer, createMigrate } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"
import "styles/main.scss"

const middleware = applyMiddleware(thunk)

const persistConfig = {
  key: "dashboard",
  version: 1,
  storage,
  whitelist: ["auth", "app"],
  migrate: createMigrate(migrations, {
    debug: import.meta.env.VITE_APP_DEBUG_REDUX_PERSIST === "true"
  })
}
const persistedReducer = persistReducer(persistConfig, reducer)
const store = createStore(persistedReducer, composeWithDevTools({})(middleware))
const persistor = persistStore(store)

export type RootState = ReturnType<typeof persistedReducer>

datadogInit(datadogConfig)

const rootEl = document.getElementById("root")

if (rootEl) {
  createRoot(rootEl).render(
    <ErrorBoundary>
      <Provider store={store}>
        <Localization>
          <PersistGate loading={null} persistor={persistor}>
            <Theme name={themeName}>
              <MsalEntryProvider
                app="dashboard"
                appApi={import.meta.env.VITE_APP_API || ""}
                serviceZone={import.meta.env.VITE_APP_SERVICE_ZONE}
                serviceZoneAlt={import.meta.env.VITE_APP_SERVICE_ZONE_ALT}>
                <BrowserRouter basename={import.meta.env.BASE_URL}>
                  <App />
                </BrowserRouter>
              </MsalEntryProvider>
            </Theme>
          </PersistGate>
        </Localization>
      </Provider>
    </ErrorBoundary>
  )
}

export default store
