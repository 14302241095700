import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { getAppId, loginRequest } from "@doktor-se/bones-ui/dist/web-shared/config"
import { authorizeWithMS } from "api/auth"
import { featureFlags } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import React, { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"

interface AuthorizedRouteProps {
  children: React.ReactNode
}

const AuthorizedRoute = ({ children }: AuthorizedRouteProps) => {
  const dispatch = useAppDispatch()
  const authorized = useAppSelector(state => state.auth.authorized)
  const token = useAppSelector(state => state.auth.accessToken)
  const navigate = useNavigate()
  const location = useLocation()
  const { inProgress, instance } = useMsal()

  useEffect(() => {
    if (!authorized && !token) {
      if (!location.pathname.startsWith("/login")) navigate("/login")

      if (featureFlags.has("sso") && inProgress === InteractionStatus.None) {
        const account = instance.getActiveAccount()
        if (account) {
          instance
            .acquireTokenSilent({ ...loginRequest, account })
            .then(res => {
              if (res) {
                dispatch(authorizeWithMS(res?.idToken, getAppId(instance)))
              }
            })
            .catch(() => {
              if (!location.pathname.startsWith("/login")) navigate("/login")
            })
        } else {
          if (!location.pathname.startsWith("/login")) navigate("/login")
        }
      }
    } else if (authorized && token && location.pathname.startsWith("/login")) {
      navigate("/dashboard")
    }
  }, [token, authorized, location.pathname, dispatch, navigate, instance, inProgress])

  return <>{children}</>
}

export default AuthorizedRoute
